<!--会员风采-->
<template>
  <div>
    <div class="rulesDiv">
      <div class="rulesTitle">
        会员风采
      </div>
      <div class="line-class"></div>
      <!--新闻列表-->
      <div class="notice-table contentMinHeight">
        <div class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="toDetail(item)">
          <div>
            <div class="notice-item-before"/>
            <div class="notice-item-title" :title="item.contentTitle">{{ item.contentTitle }}</div>
          </div>
          <div class="notice-date">{{ item.contentEditTime }}</div>
        </div>
      </div>
      <!--分页-->
      <div class="page">
        <el-pagination
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :current-page.sync="page"
          @current-change="pageChange"
          @size-change="handleSizeChange"
          @prev-click="prevClick"
          @next-click="nextClick"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getPageByCategoryId} from '@/utils/commonApi.js'
export default {
  name: "memberStyle",
  props:['memberStyleId'],
  data() {
      return {
        noticeList: [],
        page: 1,
        // 总条数
        tableTotal: 0,
        pageSize: 10,
      }
  },
  methods: {
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
    //根据栏目id查询文章列表
    getList() {
      let params = {
        current: this.page,
        size: this.pageSize,
      }
      params.categoryId = this.memberStyleId
      getPageByCategoryId(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.tableTotal = res.data.data.total
          this.noticeList = res.data.data.records
          /*日期显示年月日*/
          for (let i = 0; i < this.noticeList.length; i++) {
            this.noticeList[i].contentEditTime = this.noticeList[i].contentEditTime.substring(0,10)
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
    // 跳转详情
    toDetail(news) {
      let isShow = true
      let contentId = news.contentId
      let obj = {
        isShow: isShow,
        contentId: contentId,
        categoryId: this.memberStyleId,
      }
      this.$emit('showDetail', obj);
    },

  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .rulesDiv {
    transition: all .2s ease;
    background: #FFFFFF;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 23px 24px;

    .rulesTitle {
      font-size: 20px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #1A5BC5;
      line-height: 30px;
      text-align: left;
    }

    .line-class {
      margin-top: 20px;
      width: 910px;
      border: 1px solid #EBEFF6;
    }

    .notice-table {
      width: 910px;
      //min-height: 389px;
      .notice-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        height: 50px;

        .notice-item-title {
          position: relative;
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
          width: 432px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }

        .notice-item-before {
          position: relative;
          margin: 16px 10px 0px 0px;
          height: 7px;
          width: 7px;
          background: #bacff0;
          border-radius: 50%;
          content: "";
          float: left;
        }

        .other {
          width: 164px;
          color: #F0A42D;
        }

        .notice-date {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #9FACB9;
          line-height: 40px;
        }
      }

      .notice-item:hover {
        cursor: pointer;

        .notice-item-before {
          position: relative;
          margin: 16px 10px 0px 0px;
          height: 7px;
          width: 7px;
          background: #528ffa;
          border-radius: 50%;
          content: "";
          float: left;
        }

        .notice-item-title {
          color: #528ffa;
        }

      }

    }

    .page {
      text-align: right;
    }


  }
</style>
