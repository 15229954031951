<!--协会会员-->
<template>
  <div>
    <div class="rulesDiv">
      <div class="rulesTitle">
        协会会员 （{{viceNum + directorNum + memberNum}}）
      </div>
      <div class="line-class"></div>
      <div class="memberTable">
        <!--折叠面板-->
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="" name="1">
            <!--标题-->
            <template slot="title">
              <div class="unitTitle">
                <div class="imgTitle">
                  <img src="@/assets/img/viceBlue.png" alt="" style="margin-top: 13px">
                </div>
                <div class="titleName">
                  副会长单位 （{{viceNum}}）
                </div>
              </div>
            </template>
            <!--内容-->
            <div class="memberCard">
              <div v-for="(item, index) in viceList" :key="index" class="unitName" v-if="item.hadPay == 1">
                <div class="unit-card-name">
                  <el-divider direction="vertical"></el-divider>
                  <span style="margin-left: 20px">{{ item.associatorOrg }}</span>
                </div>
              </div>

              <div v-for="(item, index) in viceList" :key="index" class="unitNameNo" v-if="item.hadPay != 1">
                <div class="unit-card-name-no">
                  <el-divider direction="vertical"></el-divider>
                  <span style="margin-left: 20px">{{ item.associatorOrg }}</span>
                </div>
              </div>

            </div>
          </el-collapse-item>
          <el-collapse-item title="" name="2">
            <!--标题-->
            <template slot="title">
              <div class="unitTitle">
                <div class="imgTitle">
                  <img src="@/assets/img/directorBlue.png" alt="" style="margin-top: 13px">
                </div>
                <div class="titleName">
                  理事单位 （{{directorNum}}）
                </div>
              </div>
            </template>
            <!--内容-->
            <div class="memberCard">
              <div v-for="(item, index) in directorList" :key="index" class="unitName" v-if="item.hadPay == 1">
                <div class="unit-card-name">
                  <el-divider direction="vertical"></el-divider>
                  <span style="margin-left: 20px">{{ item.associatorOrg }}</span>
                </div>
              </div>

              <div v-for="(item, index) in directorList" :key="index" class="unitNameNo" v-if="item.hadPay != 1">
                <div class="unit-card-name-no">
                  <el-divider direction="vertical"></el-divider>
                  <span style="margin-left: 20px">{{ item.associatorOrg }}</span>
                </div>
              </div>

            </div>
          </el-collapse-item>
          <el-collapse-item title="" name="3">
            <!--标题-->
            <template slot="title">
              <div class="unitTitle">
                <div class="imgTitle">
                  <img src="@/assets/img/blueMember.png" alt="" style="margin-top: 13px">
                </div>
                <div class="titleName">
                  会员单位 （{{memberNum}}）
                </div>
              </div>
            </template>
            <!--内容-->
            <div class="memberCard">
              <div v-for="(item, index) in memberList" :key="index" class="unitName" v-if="item.hadPay == 1">
                <div class="unit-card-name">
                  <el-divider direction="vertical"></el-divider>
                  <span style="margin-left: 20px">{{ item.associatorOrg }}</span>
                </div>
              </div>

              <div v-for="(item, index) in memberList" :key="index" class="unitNameNo" v-if="item.hadPay != 1">
                <div class="unit-card-name-no">
                  <el-divider direction="vertical"></el-divider>
                  <span style="margin-left: 20px">{{ item.associatorOrg }}</span>
                </div>
              </div>

            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
export default {
  name: 'membership',
  data () {
    return {
      activeNames: [],
      viceList: [],
      directorList: [],
      memberList: [],
      allNum: '', //总数量
      viceNum: '', //副会长单位数量
      directorNum: '', //理事单位数量
      memberNum: '', //会员单位数量
      memberName: '', //路由带过来的名称
    }
  },
  mounted() {
    this.showWhich()
    this.getData()
  },
  methods: {
    showWhich() {
      this.memberName = this.$route.query.name; //副会长、理事、会员
      if (this.memberName == "副会长") {
        this.activeNames = ["1"]
      }
      if (this.memberName == "理事") {
        this.activeNames = ["2"]
      }
      if (this.memberName == "会员") {
        this.activeNames = ["3"]
      }
    },

    handleChange(val) {

    },
    getData() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/member/queryMember',
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.memberList = res.data.data[0].memberList
          this.memberNum = this.memberList.length
          this.directorList = res.data.data[0].directorList
          this.directorNum = this.directorList.length
          this.viceList = res.data.data[0].viceList
          this.viceNum = this.viceList.length
        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/common.less';
.rulesDiv {
  transition: all .2s ease;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 23px 24px;

  .rulesTitle {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #1A5BC5;
    line-height: 30px;
    text-align: left;
  }

  .line-class {
    margin-top: 20px;
    width: 933px;
    border: 1px solid #EBEFF6;
  }

  .memberTable {
    min-height: 409px;
    .unitTitle {
      font-weight: bold;
      text-align: left;
      margin-top: 20px;
      overflow: hidden;
      display: flex;

      .imgTitle {
      }

      .titleName {
        margin-left: 10px;
        margin-top: 5px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
      }
    }

    .memberCard {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .unitName {
        height: 50px;
        line-height: 50px;
        width: 49%;
        text-align: left;
        margin-top: 20px;
        background: linear-gradient(270deg, #FFFFFF 0%, #E6F2FF 100%);
        border-radius: 5px;
        //border-left: 3px solid #A9C6F3 ;

        .unit-card-name {
          //margin-left: 20px;
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #49618A;

          ::v-deep .el-divider {
            width: 3px;
            background-color: #A9C6F3
          }
        }
      }

      .unitNameNo {
        height: 50px;
        line-height: 50px;
        width: 49%;
        text-align: left;
        margin-top: 20px;
        background: linear-gradient(270deg, #FFFFFF 0%, #EBEEF2 100%);;
        border-radius: 5px;
        //border-left: 3px solid #A9C6F3 ;

        .unit-card-name-no {
          //margin-left: 20px;
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #49618A;

          ::v-deep .el-divider {
            width: 3px;
            background-color: #D8D8D8
          }
        }
      }

    }

  }
}


::v-deep .el-divider--vertical {
  margin: 0px;
  height: 25px;
}


</style>
