<!--会员之家-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col class="rowSpace">
        <!--   左侧菜单栏   -->
        <div class="leftDiv">
          <left-menu :menu-list="leftMenu" @menuActive="clickMenu"></left-menu>
        </div>
        <!--   右侧区域   -->
        <div class="rightDiv">
          <membership v-if="menuIndex === 0"></membership>
          <!--会员风采-->
          <member-style v-if="menuIndex === 2 && detailShow == false" @showDetail="getShow" :memberStyleId="memberStyleId"></member-style>
          <!--详情-->
          <style-detail v-if="menuIndex === 2 && detailShow == true" :contentId="contentId" :categoryId="categoryId"></style-detail>
          <member-equity v-if="menuIndex === 1"></member-equity>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import leftMenu from '@/components/leftMenu'
import membership from '@/views/giia/member/membership'
import memberStyle from '@/views/giia/member/memberStyle'
import memberEquity from '@/views/giia/member/memberEquity'
import styleDetail from '@/views/giia/member/memberStyleDetail.vue'
import {getDirectChildById} from '@/utils/commonApi.js'
export default {
  name: "index",
  components: { topPathList, leftMenu, membership, memberStyle, memberEquity, styleDetail },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标协会',
        },
        {
          name: '会员之家',
          path: '/giia/member'
        },
      ],
      leftMenu: [
        { name: '协会会员' },
        { name: '会员权益' },
        { name: '会员风采' },
      ],
      menuIndex: 0,
      detailShow: false,
      memberStyleId: ""
    }
  },
  mounted () {
    this.getLeftMenu()
  },
  methods: {
    getLeftMenu() {
      let categoryId = this.$route.query.categoryId
      let params = {
        categoryId: categoryId,
      }
      if (params.categoryId != undefined) { //从导航栏点击进入
        getDirectChildById(params).then(res => {
          if (res.data.code === 10000 || res.data.code === 200) {
            let arr =  res.data.data
            //给左侧菜单赋值
            let newArr = []
            for (let i = 0; i < arr.length; i++) {
              newArr.push(arr[i].categoryTitle)
              this.leftMenu = newArr.map(item => ({
                name: item
              }))
            }
            //获取会员风采的栏目id
            for (let j = 0; j < arr.length; j++) {
              if (arr[j].categoryTitle == "会员风采") {
                this.memberStyleId = arr[j].categoryId
              }
            }
          } else {
            this.$message.warning("请求失败")
          }
        }).catch(err => {
          this.$message.error('请求失败')
        })
      } else {//从会员风采点击进入
        this.memberStyleId = "741660077806854144" //暂时写死，没有栏目id查不到数据
      }
    },
    clickMenu({ menu, index }) {
      this.menuIndex = index
      this.detailShow = false
    },

    getShow(obj) {
      this.detailShow = obj.isShow
      this.contentId = obj.contentId
      this.categoryId = obj.categoryId
    }

  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .main-div {
    background-color: #f8f9fa !important;
    text-align: center;
  }
  .rowSpace {
    display: flex;
  }
  .leftDiv {
    width: 15%;
  }
  .rightDiv {
    width: 82%;
    background: white;
    border-radius: 5px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 1366px) {
    .main-div {
      background-color: #f8f9fa !important;
      text-align: center;
    }
    .rowSpace {
      display: flex;
    }
    .leftDiv {
      width: 15%;
    }
    .rightDiv {
      width: 82%;
      background: white;
      border-radius: 5px;
      margin-bottom: 40px;
    }
  }

</style>
