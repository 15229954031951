<template>
  <div>
    <div v-for="(item, index) in menuList" :key="index" @click="clickMenu(item, index)"
      class="cursorPointer" :class="menuIndex === index ? 'active' : 'unActive'" >
      <div class="backgroundImg" />
      <span class="leftFontClass">{{item.name}}</span>
      <div class="index-triangle" v-if="menuIndex === index"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftMenu',
  props: {
    menuList: {
      type: Array,
      immediate: true,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      menuIndex: 0,
    }
  },
  methods: {
    clickMenu(menu, index) {
      this.menuIndex = index;
      this.$emit('menuActive', { menu, index })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.active {
  width: 172px;
  height: 56px;
  background: #186CF5;
  border-radius: 5px;
  margin: 0 0 10px 0;
  .leftFontClass {
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 56px;
  }
  .backgroundImg {
    height: 16px;
    width: 23px;
    background-repeat: no-repeat;
    position: absolute;
    margin: 20px 0 20px 15px;
    background-image: url("~@/assets/img/giEdifice/activeIcon.svg");
    background-size: 23px 16px;
  }
}
.unActive {
  width: 172px;
  height: 56px;
  background: #E5ECF5;
  border-radius: 5px;
  margin: 0 0 10px 0;
  .leftFontClass {
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
  }
  .backgroundImg {
    height: 16px;
    width: 23px;
    background-repeat: no-repeat;
    position: absolute;
    margin: 20px 0 20px 15px;
    background-image: url("~@/assets/img/giEdifice/unActiveIcon.svg");
    background-size: 23px 16px;
  }
}
.index-triangle {
  position: relative;
  left: 170px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  width: 0;
  height: 0;
  bottom: 38px;
  transform: rotate(90deg);
  border-color: transparent transparent #186CF5 transparent;
}
</style>
