<!--会员权益-->
<template>
  <div>
    <div class="rulesDiv">
      <div class="rulesTitle">
        会员权益
      </div>
      <div class="line-class"></div>
      <div class="tableEquity contentMinHeight">
        <el-table
          :data="tableList"
          border
          class="list-table"
          stripe
          :header-cell-class-name='headerCellStyle'
          :span-method="objectSpanMethod"
          style="width: 100%">
          <el-table-column
            label="序号"
            :index="indexMethod"
            width="55"
            align="center"
            type="index">
          </el-table-column>
          <el-table-column
            prop="vicePresident"
            label="副会长"
            align="center">
          </el-table-column>
          <el-table-column
            prop="councilMembers"
            label="理事单位"
            align="center">
          </el-table-column>
          <el-table-column
            prop="corporateMember"
            label="企业会员"
            align="center">
          </el-table-column>
        </el-table>
        <!--联系方式-->
        <div class="memberContact">
          <span style="font-weight: bold">联系方式：</span>{{unit}}、{{brandConnHead}}、{{brandConnection}}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getMemberEquity } from '@/utils/commonApi'
import axios from 'axios'

export default {
  name: 'memberEquity',
  data () {
    return {
      // 合并行
      mergeArr: [],
      // 排序号
      indexNum: {},
      tableList: [],
      unit: '',
      brandConnHead: '',
      brandConnection: '',
    }
  },
  methods: {
    //获取联系信息
    getContact() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/app/memberContact',
      }).then(res => {
        if (res.data.code == 200 || res.data.code == 10000) {
          this.unit = res.data.data.unit
          this.brandConnHead = res.data.data.brandConnHead
          this.brandConnection = res.data.data.brandConnection
        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //获取后台返回的数据
    getList () {
      let that = this
      getMemberEquity().then(res => {
        if (res.code === 10000) {
          let data = res.data
          that.tableList = data.memberEquity
          that.hebing()
        }
      })
    },
    hebing () {
      let that = this
      // 计算合并的行
      that.mergeArr = that.colMethod(['equitySort'], that.tableList)
      // 浅拷贝处理数据
      let dataBase = Array.from(that.tableList)
      let saleIdArr = that.colMethod(['equitySort'], dataBase)

      // 先根据saleId合并第一次，将数据源根据合并下标处理为多段数据源
      let newData = []
      saleIdArr.equitySort.forEach((item, index) => {
        if (item[0] > 0) {
          let res = []
          for (let i = 0; i < item[0]; i++) {
            res.push(dataBase.slice(0, 1)[0])
            this.$delete(dataBase, 0)
          }
          newData.push(res)
        }
      })

      //排列序号
      this.indexObj()
    },
    colMethod (columnArr, data) {
      // columnArr 合并行所在的列字段
      // data 需要合并的表格数据
      let column = {}
      let position = 0
      // 遍历合并的列数据
      columnArr.forEach((prop) => {
        column[prop] = []
        //  遍历合并的行数据
        data.forEach((row, rowIndex) => {
          // 第N列第一行
          column[prop][rowIndex] = [1, 1]
          if (rowIndex === 0) {
            // 记录当前行号
            position = 0
          } else if (row[prop] === data[rowIndex - 1][prop]) {
            // 当前行数据等于上一行，根据记录的行号，计算需要合并几行。
            column[prop][position][0] += 1
            // 当前行 隐藏不显示
            column[prop][rowIndex][0] = 0
          } else {
            // 不相等之后，重置记录行号
            position = rowIndex
          }
        })
      })
      return column
    },

    // 排列序号
    indexObj () {
      let num = 0
      this.mergeArr['equitySort'].forEach((item, index) => {
        if (item[0] !== 0) {
          this.indexNum[index] = num += 1
        }
      })
    },

    // 返回序号
    indexMethod (index) {
      return this.indexNum[index]
    },

    // 表头样式
    headerCellStyle ({ column }) {
      return 'heard-class'
    },

    //合并行
    //当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性
    //需要合并的行设置相同的id，根据id进行行合并
    objectSpanMethod ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      let arr = this.mergeArr[column.property] || []
      if (column.type == 'index' && this.mergeArr['equitySort']) {
        return this.mergeArr['equitySort'][rowIndex]
      } else if (arr.length) {
        return arr[rowIndex]
      } else {
        [1, 1]
      }
    }
  },
  mounted () {
    this.getList()
    this.getContact()
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/common.less';
.rulesDiv {
  transition: all .2s ease;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 23px 24px 40px 24px;

  .rulesTitle {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #1A5BC5;
    line-height: 30px;
    text-align: left;
  }

  .line-class {
    margin-top: 20px;
    width: 910px;
    border: 1px solid #EBEFF6;
  }
}

.list-table {
  margin-top: 10px;
  margin-bottom: 20px;

  ::v-deep .heard-class {
    background: #E6F1FF;
    border: 1px solid #D8E5F8;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #485062;
    line-height: 21px;
    text-align: center;
  }

  ::v-deep .el-table__row--striped > td {
    background: #F4F9FF !important;
  }
}
.memberContact {
  float: right;
}

</style>
